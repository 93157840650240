<!--
 * @Description: 填写收货单
 * @Author: zhang zhen
 * @Date: 2023-05-30 11:26:43
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-29 09:55:31
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadShippedFileImportant.vue
-->
<template>
  <DrawerView :visible="visible" :width="990" :title="'填写收货单'" show-slot-title
              @cancel="handleCancel">

    <a-tabs v-model="activeKey">
      <a-tab-pane :key="1" tab="待收货">
      </a-tab-pane>
      <a-tab-pane :key="2" tab="收货记录">
      </a-tab-pane>
    </a-tabs>
    <div v-if="activeKey==1">
      <EmptyArea v-if="!warningList.length" description="暂无待收货" style="height: 250px" />

      <a-tabs v-model="activePart" type="card" v-if="warningList.length" tab-position="top" class="step_form">
        <a-tab-pane v-for="(item, index) in warningList" :key="index">
          <template #tab>
            <span class="label-span" style="margin-right: 15px "  >
            待收货
              {{ index + 1 }}</span>
          </template>
          <!-- 没发货信息 -->
          <div class="noPassInfo" v-if="!item.shipmentStatus || item.shipmentStatus == 1">
            <img src="/noTransport.png" alt="" class="icon" />
            <span class="memo">暂无发货信息</span>
          </div>
          <template v-else>
            <a-form-model
              class="info-form"
              :label-col="{
              span: 6,
            }"
              :wrapper-col="{
              span: 18,
            }"
              :colon="false"
            >
              <h2 class="page-title">
                <span class="title">待收货信息</span>
              </h2>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item
                    label="发货目的地"
                    :label-col="{
                    span: 3,
                  }"
                    :wrapper-col="{
                    span: 21,
                  }"
                  >
                    <a-select v-model="selectedValue" style="width: 818px" disabled>
                      <a-select-option>
                        {{ item.receiveProvinceName }}
                        {{ item.receiveCityName }}
                        {{ item.receiveAreaName }}
                        {{ item.receiveDetailAddress }}
                      </a-select-option>
                    </a-select>

                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="发货方式" prop="shipmentType" required>
                    <j-dictSelect
                      v-model="item.shipmentType"
                      style="width: 100%"
                      placeholder="请选择"
                      dictCode="1018"
                      :disabled="true"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="运单号" prop="shipmentNo" v-if="item.shipmentType != '1018002'" required>
                    <a-input
                      placeholder="请输入"
                      v-model="item.shipmentNo"
                      style="width: 100%"
                      :disabled="true"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12">
                  <a-form-model-item label="发货时间" prop="shipmentTime" required>
                    <JDate
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="item.shipmentTime"
                      :removePast="true"
                      :disabled="true"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
            <!-- 收货信息 -->
            <h2 class="page-title" style="margin-top: 0px">
              <span class="title">收货数量</span>
            </h2>
            <a-table class="table-box" :columns="columns" :pagination="false" :data-source="item.shipmentItems"
                     bordered>
              <template slot="receiveQuantity" slot-scope="text, record, index">
                <div style="display: flex;gap: 10px;align-items: center">
                  <a-input-number
                    id="inputNumber"
                    v-model="record.receiveQuantity"
                    :min="0"
                    :max="record.shipmentQuantity"
                    placeholder="请填写实际收货数量"
                    :precision="0"
                  />

                  {{ record.skuUnitDictName }}
                </div>
              </template>
            </a-table>

            <h2 class="page-title">
              <span class="title">收货单据</span>
            </h2>
            <!-- 预览单据图片 -->
            <div class="order-pic" v-if="false">
              <img preview="1" src="/order_pic.png" alt="" class="cover" />
              <a>下载</a>
            </div>
            <a-form-model :colon="false">
              <a-form-model-item>
                <div class="uploadCover">
                  <UploadDrag
                    v-model="item.uploadFiles"
                    @change="handleChangeFile($event, item)"
                    :number="1"
                    :orderNo="item.orderNo"
                    :uploadUrl="item.receiveFile"
                  />
                </div>
                <!--                :uploadUrl="item.receiveFile"-->
              </a-form-model-item>
            </a-form-model>
          </template>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div v-if="activeKey==2">
      <EmptyArea v-if="!transportsVo.length" description="暂无收货记录" style="height: 250px" />
      <div v-if="transportsVo.length" v-for="(item,index) in transportsVo" style="padding-top: 20px;">
        <h2 class="page-title ">
          <span class="title">收货信息 {{ getNumberToChinese(index + 1) }}</span>
        </h2>
        <div class="receiveInfo">
          <div style="display: flex ;padding: 10px 24px">
            <div style="width: 300px;padding-right: 40px;">收货人：{{ item.receiveName }}</div>
            <div style="width: 300px;">收货人电话：{{ item.receivePhone }}</div>
          </div>
          <div style="padding: 10px 24px">收货地址：{{ item.receiveProvinceName }}{{ item.receiveCityName
            }}{{ item.receiveAreaName }}{{ item.receiveDetailAddress }}
          </div>
        </div>
        <div class="receive-order">
          <div class="receive-descbox">
            <div class="receive-th">
              <div class="desc-title">「收货单 {{ getNumberToChinese(index + 1) }}」</div>
              <div class="desc-time"> 收货时间：&nbsp;
                <span v-if="item.realReceiveDate==null">{{item.expectedDeliveryDate}}</span>
                <span v-else>{{ dayjs(item.realReceiveDate).format('YYYY-MM-DD') || '-' }}</span>
            </div>
            <div class="desc-operate">
              <span v-if="item.receiveStatus==2" @click="onReceiveDownload(item)"> 下载收货单</span>
            </div>
          </div>
          <div class="receive-tr">
            <div class="receive-td">发货方式：{{ item.shipmentTypeDictName }}</div>
            <div class="receive-td"> 物流编号：{{ item.shipmentNo || '-' }}</div>
            <div class="receive-td" style=" border-right:none;">
              <div style="display: flex;align-items: center">
                <span>收货状态：</span>
                <div v-if="item.receiveStatus==1" style="display: flex;align-items: center">
                  <img src="@/assets/warning-tip.png" style="width: 16px;height: 16px;margin-right: 4px" />
                  <span style="color: #EE4261">未收货</span>
                </div>
                <div v-if="item.receiveStatus==2" style="display: flex;align-items: center">
                  <img src="@/assets/success-goods.png" style="width: 16px;height: 16px;margin-right: 4px" />
                  <span style="color: #05C29C">已收货</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-table class="table-box" :columns="recolumns" :pagination="false" :data-source="item.shipmentItems"
                 bordered>
          <template slot="receiveQuantity" slot-scope="text, record, index">
              <span v-if="text==0">
                {{ record.shipmentQuantity }}
              </span>
            <span v-else>{{ record.receiveQuantity }}</span>
          </template>

        </a-table>
      </div>
    </div>
    </div>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65)">取消</a-button>
      <a-button @click="handleSubmit"
                v-if="(warningList.receiveStatus ==null || warningList.receiveStatus==1 ) && activeKey==1"
                type="primary" :loading="loading">确认收货
      </a-button>
    </template>
  </DrawerView>
</template>

<script>
import UploadDrag from './uploadTemplate.vue'
import { postAction, getAction, downGetFiles } from '@/api/manage'
import JDate from '@/components/easyComponents/JDate.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import DrawerView from '@/components/plugins/drawerView.vue'
import dayjs from 'dayjs'
import EmptyArea from '@/components/plugins/EmptyArea.vue'

export default {
  name: 'uploadShippedFileImportant',
  components: {
    EmptyArea,
    UploadDrag,
    DrawerView,
    JDate,
    JDictSelect
  },
  inject: ['handleReloadData'],
  data() {

    return {
      activePart: 0,
      visible: false,
      loading: false,
      uploadFiles: [],
      orderNo: null,
      transportsVo: [],
      columns: [
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width: 100
        }, {
          title: '需求名称',
          dataIndex: 'skuName',
          width: 173
        },
        {
          title: '所需数量',
          dataIndex: 'skuPlanQuantity',
          width: 150
        },
        {
          title: '已收货数量',
          dataIndex: 'finishedReceiveQuantity',
          width: 130
        },
        {
          title: '当前发货数量',
          dataIndex: 'shipmentQuantity',
          width: 130
        },
        {
          title: '实际收货数量',
          dataIndex: 'receiveQuantity',
          width: 200,
          scopedSlots: { customRender: 'receiveQuantity' }
        }
      ], recolumns: [
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width: 311,
          scopedSlots: { customRender: 'wrapTypeDictName' }
        },
        {
          title: '需求名称',
          dataIndex: 'skuName',
          width: 311,
          scopedSlots: { customRender: 'skuName' }
        },
        {
          title: '收货数量',
          dataIndex: 'receiveQuantity',
          width: 311,
          scopedSlots: { customRender: 'receiveQuantity' }
        }
      ],
      dataSource: [],
      receiveRealName: null,
      activeKey: 1,
      selectedValue: '',
      warningList:[]
    }
  },
  methods: {
    dayjs,
    handleOpen({ orderNo, purchaseBusinessName }) {
      this.visible = true
      this.orderNo = orderNo
      this.receiveRealName = purchaseBusinessName
      this.handleLoadOrder()
    },
    handleLoadOrder() {
      getAction('/order/actualShipment/queryByOrderNo', {
        orderNo: this.orderNo
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.transportsVo = data && data.map(i => ({
            ...i,
            shipmentTime: i.shipmentTime && dayjs(new Date(i.shipmentTime)).format('YYYY-MM-DD') || null
          })) || []
          //待收货list
          this.warningList=JSON.parse(JSON.stringify(this.transportsVo.filter(item=>item.shipmentStatus == 2 && item.receiveStatus==1).map(item=>({...item}))))
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleChangeFile(e, item) {
      debugger
      item.uploadFiles = e.splice(-1, 1)
      item.receiveFile = item.uploadFiles[0]['url']
    },
    handleSubmit() {
      debugger
      // if (!this.uploadFiles.length) return this.$message.warning('请上传收货单据文件')
      const { receiveFile, shipmentItems, orderNo, id, shipmentStatus, receiveStatus } =
        this.warningList[this.activePart]
      if (!shipmentStatus) return this.$message.warning('当前收货单尚未发货')
      if (receiveStatus && receiveStatus == 2) return this.$message.warning('当前收货单已收货')
      if (!receiveFile) return this.$message.warning('请上传收货单据文件')
      let shipList = []
      for (const element of shipmentItems) {
        if (!element.receiveQuantity) return this.$message.warning('请填写收货数量')
        const { id, receiveQuantity, shipmentQuantity } = element
        shipList.push({
          id,
          receiveQuantity,
          shipmentQuantity
        })
      }
      let formData = {
        receiveFile,
        orderNo,
        id,
        receiveRealName: this.receiveRealName,
        shipmentItems: shipList,
        realReceiveDate: dayjs(new Date()).format('YYYY-MM-DD'),
        receiveStatus: 2
      }
      this.confirmLoading = true
      postAction('/order/actualShipment/submitReceive', formData)
        .then((res) => {
          const { success, message } = res
          this.confirmLoading = false
          if (success) {
            this.handleReloadData(1) // 刷新主列表
            this.$message.success('收货成功')
            this.handleCancel()
            // this.transportsVo[this.activePart]['receiveStatus'] = 2
            // let flag = this.transportsVo.every((i) => i.receiveStatus == 2)
            // if (flag) {
            //   this.handleCancel()
            // }
          } else {
            this.$message.warning(message)
          }
        })
        .catch((e) => {
          this.confirmLoading = false
        })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.uploadFiles = []
      this.activePart = 0
    }
    , getNumberToChinese(num) {
      let chineseNums = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
      let result = String(num).split('').map(digit => chineseNums[parseInt(digit)]).join('')
      return result
    },
    onReceiveDownload(item) {
      downGetFiles('/order/receive/download', {
        id: item.id
      }).then((res) => {
        let url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', window.TemplateName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // 下载完成移除元素
        window.URL.revokeObjectURL(url) // 释放掉blob对象
      })

    }
  }
}
</script>

<style lang="less" scoped>
.uploadCover {
  width: 740px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #efefef;

  .endTitle {
    width: 424px;
    margin-top: -15px;
    text-align: center;
    line-height: 20px;
    // .flexLayout();
    font-size: 12px;
    // justify-content: center;
    color: #8c8c8c;
    margin-top: 8px;

    span.text {
      color: #ff6026;
    }
  }
}

.ant-form-item-label > label {
  color: #000;
}

.part-title {
  margin-top: 24px;
  //styleName: 14/加粗;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #000;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding-bottom: 8px;
  margin-bottom: 25px;

  &::before {
    display: block;
    content: '';
    width: 5px;
    height: 16px;
    background: #ff6026;
    margin-right: 8px;
  }
}

.table-box {
  min-height: auto !important;

  ::v-deep .ant-table-tbody tr:not(:last-of-type) > td {
    border-right: 1px solid #e8e9eb !important;
  }

  ::v-deep .ant-table-thead > tr > th {
    background: #f4f5f7;
    border-right: 1px solid #e8e8e8 !important;
    border-bottom: 1px solid #e8e8e8 !important;

    padding: 8px 10px;
    font-size: 14px;
  }

  ::v-deep .ant-table-column-title {
    font-size: 14px;
  }

  ::v-deep .ant-table-tbody tr > td {
    border-bottom: 1px solid #e8e9eb !important;
    padding: 5px 10px;
  }

  ::v-deep .ant-table-wrapper {
    min-height: auto !important;
    border-bottom: none;
  }

  ::v-deep .ant-input-number {
    width: 100%;
    height: 28px;
    line-height: 28px;

    .ant-input-number-input {
      height: 28px;
      font-size: 14px;
      font-weight: 350;

      &::placeholder {
        color: #8c8c8c;
        /* 设置占位符文本的颜色 */
      }
    }
  }

  // ::v-deep .ant-input {
  //   border: none;
  //   outline: 0;

  //   &:focus {
  //     border: none;
  //     box-shadow: none;
  //   }
  // }

  ::v-deep .ant-input-number-handler-wrap {
    display: none;
  }
}

.step_form {
  ::v-deep .ant-tabs-content {
    padding-left: 25px;
  }
}

// ::v-deep .ant-tabs-ink-bar-animated {
//   height: 24px!important;
// }
::v-deep .ant-tabs-nav .ant-tabs-tab {
  padding: 1px 0;
  margin-bottom: 24px;
  //display: flex;
  //align-items: center;
}

::v-deep .ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

img.to_input {
  width: 52px;
  margin-right: 15px;
}

span.label-span {
  display: inline-block;
  //width: 90px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page-title {
  &:not(:first-of-type) {
    margin-top: 28px;
  }

  &:first-of-type {
    margin-top: -8px;
  }

  font-size: 15px;
  color: #605f5f;
  margin-top: 8px;
  margin-bottom: 24px;
  height: 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  position: relative;

  &:before {
    background: #FF6026;
    display: inline-block;
    content: '';
    width: 5px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 10px;
  }

  .action {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: #ff6026;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;

    img.icon {
      width: 16px;
      margin-right: 4px;
    }
  }
}

.info-form {
  ::v-deep .ant-select-selection {
    height: 38px;
  }

  ::v-deep .ant-select-selection__rendered {
    line-height: 38px;
  }

  ::v-deep .ant-calendar-picker-input.ant-input {
    height: 38px;
    line-height: 38px;
  }

  ::v-deep .ant-select-selection--multiple {
    padding-bottom: 0;
  }

  ::v-deep .ant-select-selection--multiple .ant-select-arrow {
    top: 18px;
  }

  ::v-deep .ant-select-arrow {
    margin-top: -8px;
  }

  ::v-deep .ant-input {
    height: 38px;
    line-height: 38px;

    // color: #131212;
    &::placeholder {
      color: #8c8c8c;
      /* 设置占位符文本的颜色 */
    }
  }

  ::v-deep .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  ::v-deep .ant-select-selection__placeholder {
    color: #8c8c8c;
  }
}

.noPassInfo {
  margin: 134px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img.icon {
    width: 160px;
    margin-bottom: 15px;

    .memo {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      color: #131212;
    }
  }
}

.order-pic {
  width: 300px;
  display: flex;
  align-items: flex-end;

  img.cover {
    width: 240px;
    height: 164px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
}


.receiveInfo {
  display: flex;
  flex-direction: column;
  background: #F7F8FA;
  border-radius: 4px;
  padding: 16px 0px;
  /* 14/常规 */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* or 22px */
  color: #595959;
}

.receive-order {
  margin-top: 24px;
}

::v-deep .receive-order .ant-descriptions-view {
  border-radius: 4px 4px 0px 0px !important;

}

::v-deep .receive-order .ant-table-wrapper {
  border-bottom: none !important;
}

::v-deep .receive-order .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0px !important;
  border-top: 0px !important;
}

::v-deep .receive-order .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0px !important;
}

::v-deep .receive-order .ant-table-body table {
  border-top: none !important;
}

.receive-order {
  border-top-left-radius: 0px !important;
}

::v-deep .receive-order table {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  box-shadow: none !important;
}

.send-delivery {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #131212;

}

.desc-title {

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #131212;
  width: 311px;
  padding: 8px 16px;


}

.desc-time {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #131212;
  width: 311px;
  padding: 8px 16px;


}

.desc-operate {
  width: 311px;
  padding: 8px 16px;
  /* 14/常规 */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* 品牌主题色 */
  color: #FF6026;
  justify-content: flex-end;

}

.receive-th {
  display: flex;
  background: #FAFAFA;
  border: 1px solid #E8E9EB;
  justify-content: space-between;
}

.receive-tr {
  display: flex;
  border: 1px solid #E8E9EB;
  border-top: none;

}

.receive-td {
  padding: 10px 16px;
  width: 311px;
  border-right: 1px solid #E8E9EB;

}

::v-deep .receive-order .table-box .ant-table-thead > tr > th {
  padding: 10px 16px;
}

::v-deep .receive-order .table-box .ant-table-tbody tr > td {
  padding: 10px 16px;
}
::v-deep .ant-tabs-nav-container{
  overflow:unset !important;
}
::v-deep .ant-tabs-nav-wrap{margin-bottom:0px !important;}
</style>
