<!--
 * @Description: 填写发货单
 * @Author: zhang zhen
 * @Date: 2023-05-30 11:00:59
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-23 18:00:23
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadTransportOrder.vue
-->
<template>

  <DrawerView :visible="visible" :width="990" :title="'填写发货单'" show-slot-title @confirm="handleOk"
              @cancel="handleCancel">
    <a-tabs v-model="activeKey" type="card" @change="handleSwitchTab">
      <a-tab-pane :key="1" tab="填写发货单">
      </a-tab-pane>
      <a-tab-pane :key="2" tab="发货记录">
      </a-tab-pane>
    </a-tabs>
    <div v-if="activeKey==1">
      <div class="step_form">
        <div>
          <a-form-model
            ref="basicForm"
            class="info-form"
            :label-col="{
            span: 6,
          }"
            :wrapper-col="{
            span: 18,
          }"
            :colon="false"
            :rules="rules"
            :model="info"
          >
            <h2 class="page-title ">
              <span class="title">发货信息</span>
            </h2>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="发货目的地" prop="selectedValue">
                  <a-select placeholder="请选择" style="width: 818px"
                            @change="handleProvinceChange" v-model="info.selectedValue" class="placeSelect">
                    <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon">
                    <a-select-option v-for="(item,index) in addressList" :key="index">
                      {{ item.receiveProvinceName }}
                      {{ item.receiveCityName }}
                      {{ item.receiveAreaName }}
                      {{ item.receiveDetailAddress }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="发货方式" prop="shipmentType">
                  <j-dictSelect v-model="info.shipmentType" style="width: 100%" placeholder="请选择" dictCode="1018" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="运单号" prop="shipmentNo" v-if="info.shipmentType != '1018002'">
                  <a-input placeholder="请输入" v-model="info.shipmentNo" style="width: 100%"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="发货时间" prop="shipmentTime">
                  <JDate
                    placeholder="请选择"
                    style="width: 100%"
                    v-model="info.shipmentTime"
                    :passDate="lastDate"
                    :removePast="true"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <h2 class="page-title not-first">
              <span class="title">发货数量</span>
            </h2>
            <a-table class="table-box" :columns="columns" :pagination="false" :data-source="shipmentList" bordered>
              <template slot="wrapTypeDictName" slot-scope="text, record, index">
                {{ record.wrapTypeDictName }}
              </template>
              <template slot="skuName" slot-scope="text, record, index">
                {{ record.skuName }}
              </template>
              <template slot="skuPlanQuantity" slot-scope="text, record, index">
                {{ record.skuPlanQuantity }}
              </template>
              <template slot="finishedShipmentQuantity" slot-scope="text, record, index">
                {{ record.finishedShipmentQuantity || '-' }}
              </template>
              <template slot="unfinishedShipmentQuantity" slot-scope="text, record, index">
                {{ record.unfinishedShipmentQuantity || '-' }}
              </template>
              <template slot="shipmentQuantity" slot-scope="text, record, index">
                <div style="display: flex;gap: 10px;align-items: center">
                  <a-input-number
                    id="inputNumber"
                    v-model="record.shipmentQuantity"
                    :min="0"
                    :max="record.unfinishedShipmentQuantity"
                    placeholder="请填写实际收货数量"
                    :precision="0"
                    :disabled="record.unfinishedShipmentQuantity==0?true:false"
                  />
                  {{ record.skuUnitDictName }}
                </div>
              </template>
            </a-table>
            <h2 class="page-title">
              <span class="title">发货单据</span>
            </h2>
            <div class="uploadCover">
              <UploadDrag
                v-model="info.uploadFiles"
                @change="handleChangeFile($event, info)"
                :orderNo="orderNo"
                :id="info.id"
                :number="1"
              />
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div v-if="activeKey==2">
      <EmptyArea v-if="!actualShipmentList.length" description="暂无发货记录" style="height: 250px" />
      <div v-if="actualShipmentList.length" v-for="(item,index) in actualShipmentList" style="padding-top: 20px;">
        <h2 class="page-title ">
          <span class="title">发货信息 {{ getNumberToChinese(index + 1) }}</span>
        </h2>
        <div class="receiveInfo">
          <div style="display: flex ;padding: 10px 24px">
            <div style="width: 300px;padding-right: 40px;">收货人：{{ item.receiveName }}</div>
            <div style="width: 300px;">收货人电话：{{ item.receivePhone }}</div>
          </div>
          <div style="padding: 10px 24px">收货地址：{{ item.receiveProvinceName }}{{ item.receiveCityName
            }}{{ item.receiveAreaName }}{{ item.receiveDetailAddress }}
          </div>
        </div>
        <div class="receive-order">
          <div class="receive-descbox">
            <div class="receive-th">
              <div class="desc-title">「发货单 {{ getNumberToChinese(index + 1) }}」</div>
              <div class="desc-time"> 发货时间：{{ dayjs(item.shipmentTime).format('YYYY-MM-DD') }}</div>
              <div class="desc-operate" v-if="item.shipmentStatus==1">
                <span @click="onDelete(item)" style="cursor: pointer;">删除</span><span
                style="margin-right: 6px ;margin-left: 6px;color: #E8E9EB">|</span><span
                @click="onEdit(item)" style="cursor: pointer;">编辑</span>
              </div>
              <div class="desc-operate" v-if="item.shipmentStatus==2" @click="onDeliveryDownload(item)">
                <span style="cursor: pointer;">下载发货单</span>
              </div>
            </div>
            <div class="receive-tr">
              <div class="receive-td">发货方式：{{ item.shipmentTypeDictName }}</div>
              <div class="receive-td"> 物流编号：{{ item.shipmentNo || '-' }}</div>
              <div class="receive-td" style=" border-right:none;display: flex">
                <span>发货状态：</span>
                <div v-if="item.shipmentStatus==1" style="display: flex;align-items: center">
                  <img src="@/assets/warning-tip.png" style="width: 16px;height: 16px;margin-right: 4px" />
                  <span style="color: #EE4261">未发货</span>
                </div>
                <div v-if="item.shipmentStatus==2" style="display: flex;align-items: center">
                  <img src="@/assets/success-goods.png" style="width: 16px;height: 16px;margin-right: 4px" />
                  <span style="color: #05C29C">已发货</span>
                </div>
              </div>
            </div>
          </div>
          <a-table class="table-box" :columns="recolumns" :pagination="false" :data-source="item.shipmentItems"
                   bordered>
          </a-table>
        </div>
      </div>
    </div>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65)">取消</a-button>
      <a-button v-if="activeKey==1" @click="handleSave" type="primary"
                style="background: #F9A519;border-color:  #F9A519 !important;"
                :loading="confirmLoading">保存
      </a-button>
      <a-button @click="handleOk" v-if="activeKey==1" type="primary" :loading="confirmLoading">确定发货</a-button>
    </template>
    <a-modal title="温馨提示" :visible="visibleDel" centered @ok="handleDel" @cancel="handleDelCancel">
      <div class="info-card" style="display: flex;align-items: center">
        <img src="/error.png" alt="" style="width: 24px;height: 24px;margin-right: 8px" />
        <span>删除后不可恢复，您确定要删除吗？</span>
      </div>
    </a-modal>
    <a-modal title="温馨提示" :visible="visiblePull" centered @ok="handlePull" @cancel="handlePullCancel">
      <div class="info-card" style="display: flex;align-items: center">
        <img src="/warning.png" alt="" style="width: 24px;height: 24px;margin-right: 8px" />
        <span>确认后不可编辑，您确定要发货吗？</span>
      </div>
    </a-modal>
  </DrawerView>
</template>

<script>
import { downGetFiles, getAction, postAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
import JDate from '@/components/easyComponents/JDate.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import UploadDrag from './uploadTransportTemplate.vue'
import dayjs from 'dayjs'
import region from '@/util/region.json'
import { isEmpty } from 'lodash'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import { downloadImage } from '@/util/utils'

export default {
  name: 'uploadTransportOrder',
  components: {
    EmptyArea,
    UploadDrag,
    JDate,
    DrawerView,
    JDictSelect,
    splitLine
  },
  inject: ['handleReloadData'],
  data() {
    return {
      region,
      visible: false,
      confirmLoading: false,
      activePart: 0,
      form: {
        detailAddress: ''
      },
      rules: {
        selectedValue: { required: true, message: '请选择发货目的地' },
        deliveryAddress: { required: true, message: '请选择收货地址' },
        detailAddress: { required: true, message: '请选择详细地址' },
        shipmentType: { required: true, message: '请选择发货方式' },
        shipmentNo: { required: true, message: '请输入运单号' },
        insuredAmount: { required: true, message: '请输入保价金' },
        freight: { required: true, message: '请输入运费' },
        shipmentTime: { required: true, message: '请选择发货时间' }
      },
      regionOptions: [],
      regionList: [],
      deliveryCityList: [],
      deliveryDistrictList: [],
      uploadFiles: [],
      lastDate: null,
      orderNo: null,
      newFill2: false,
      isAllUploadALl: false,
      transportsVo: [],
      purchaseType: null,
      columns: [
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width: 173,
          scopedSlots: { customRender: 'wrapTypeDictName' }
        },
        {
          title: '需求名称',
          dataIndex: 'skuName',
          width: 163,
          scopedSlots: { customRender: 'skuName' }
        },
        {
          title: '所需数量',
          dataIndex: 'skuPlanQuantity',
          width: 150,
          scopedSlots: { customRender: 'skuPlanQuantity' }
        },
        {
          title: '已发数量',
          dataIndex: 'finishedShipmentQuantity',
          width: 100,
          scopedSlots: { customRender: 'finishedShipmentQuantity' }
        },
        {
          title: '未发数量',
          dataIndex: 'unfinishedShipmentQuantity',
          width: 83,
          scopedSlots: { customRender: 'unfinishedShipmentQuantity' }
        },
        {
          title: '实际发货数量(个)',
          dataIndex: 'shipmentQuantity',
          width: 183,
          scopedSlots: { customRender: 'shipmentQuantity' }
        }
      ], recolumns: [
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width: 311,
          scopedSlots: { customRender: 'wrapTypeDictName' }
        },
        {
          title: '需求名称',
          dataIndex: 'skuName',
          width: 311,
          scopedSlots: { customRender: 'skuName' }
        },
        {
          title: '发货数量',
          dataIndex: 'shipmentQuantity',
          width: 311,
          scopedSlots: { customRender: 'shipmentQuantity' }
        }
      ],
      dataSource: [
        {
          wrapTypeDictName: '瓦楞纸',
          typName: '制造',
          planNum: 100,
          // sendNum: 100,
          receiveNum: ''
        },
        {
          wrapTypeDictName: '非瓦楞纸',
          typName: '制造',
          planNum: 90,
          // sendNum: 90,
          receiveNum: ''
        }
      ],
      shipmenUser: null,
      info: { uploadFiles: [], selectedValue: undefined },
      activeKey: 1,
      addressList: [], shipmentList: [],
      shipmentOrderNo: null,
      actualShipmentList: [],
      visibleDel: false,
      shipmentId: null,
      visiblePull: false
    }
  },
  methods: {
    dayjs,
    handleEdit({ createTime, orderNo, supplierBusinessName, purchaseType, id }) {
      this.purchaseType = purchaseType || ''
      this.lastDate = (createTime && dayjs(new Date(createTime)).format('YYYY-MM-DD')) || null
      this.visible = true
      this.orderNo = orderNo
      this.shipmenUser = supplierBusinessName
      this.handleLoadOrder()
      this.handlequeryRecordByOrderNo()

    },
    handleChangeFile(e, item) {
      item.uploadFiles = e.splice(-1, 1)
      console.log(item.uploadFiles)
      item.shipmentFile = item.uploadFiles[0]['url']
    },
    handleLoadOrder() {
      getAction('/order/query/delivery', {
        orderNo: this.orderNo
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.transportsVo = data || []
          this.addressList = this.transportsVo
          this.addressList.forEach((item, index) => {
            delete item.shipmentItems
            delete item.id
          })

        } else {
          this.$message.warning(message)
        }
      })
    },
    handlePullCancel(){
      this.visiblePull=false
    },
    handleLoadShipment(callback) {
      getAction('/order/actualShipment/queryShipmentItems', {
        shipmentOrderNo: this.shipmentOrderNo
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          if (data) {
            this.shipmentList = data || []
          }
          if (callback) {
            callback()
          }
        } else {
          this.$message.warning(message)
        }
      })
    },
    //发货记录
    handlequeryRecordByOrderNo() {
      getAction('/order/actualShipment/queryByOrderNo', {
        orderNo: this.orderNo
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.actualShipmentList = data
        } else {
          this.$message.warning(message)
        }
      })
    },
    //确认发货
    handleOk() {


      if (this.$refs.basicForm == null || this.$refs.basicForm == undefined) {
        return this.$message.warning('请选择发货目的地')
      }
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.info.receiveProvinceCode == null || this.info.receiveProvinceCode == undefined) {
            return this.$message.warning('请选择发货目的地')
          }

          //提示全部发完
          if (this.shipmentList.every((item) => item.unfinishedShipmentQuantity === 0)) {
            return this.$message.warning('目的地发货单数量已发完')
          }

          // 验证当前发货数量是不是空的， 必须填   还有发货量的数
          // this.shipmentList.map((item, index) => {
          //   if (item.unfinishedShipmentQuantity > 0) {
          //     if (item.shipmentQuantity == null || item.shipmentQuantity == undefined) {
          //       return this.$message.warning('实际发货数不能为空')
          //     } else if (item.shipmentQuantity <= 0) {
          //       return this.$message.warning('实际发货数不能小于0')
          //     } else if (item.shipmentQuantity > item.unfinishedShipmentQuantity) {
          //       return this.$message.warning('实际发货数不能大于未发数量')
          //     }
          //   }
          // })
          let tip = null
          this.shipmentList.map((item, index) => {
            if (item.unfinishedShipmentQuantity > 0) {
              if (tip == null && (item.shipmentQuantity == null || item.shipmentQuantity == undefined)) {
                tip = '需求' + (index + 1) + '实际发货数不能为空'
              } else if (item.shipmentQuantity <= 0) {
                tip = '需求' + (index + 1) + '实际发货数不能小于0'
              } else if (item.shipmentQuantity > item.unfinishedShipmentQuantity) {
                tip = '需求' + (index + 1) + '实际发货数不能大于未发数量'
              }
            }
          })
          if (tip != null) {
            return this.$message.warning(tip)
          }
          // // 验证当前发货数量是不是空的， 必须填
          // if (!this.shipmentList.every((item) => item.shipmentQuantity)) {
          //   return this.$message.warning('请填写实际发货数量')
          // }
          if (!this.info.shipmentFile) {
            return this.$message.warning('请上传发货单据')
          }
          this.visiblePull = true

        }
      })

    }, handlePull() {
      delete this.info.uploadFiles
      let formData = this.info
      formData.shipmentItems = this.shipmentList.filter(item => item.unfinishedShipmentQuantity > 0)
      formData.shipmentNo = this.info.shipmentNo
      formData.orderNo = this.orderNo
      formData.shipmentFile = this.info.shipmentFile
      formData.shipmentStatus = 2
      this.confirmLoading = true
      postAction('/order/actualShipment/saveActualShipment', formData)
        .then((res) => {
          const { success, message } = res
          this.confirmLoading = false
          if (success) {
            this.info = {}
            this.handleReloadData(1) // 刷新主列表
            this.activeKey = 2
            this.visiblePull = false

          } else {
            this.$message.warning(message)
          }
        })
        .catch((e) => {
          this.confirmLoading = false
        })
    },
    handleCancel(needNew = false) {
      this.lastDate = null
      this.visible = false
      this.confirmLoading = false
      this.uploadFiles = []
      this.activePart = 0
      this.transportsVo = []
      this.info = {}
      this.shipmentList = []
    },
    handleDelCancel() {
      this.visibleDel = false

    },
    onRegionChange(value, selectedOptions) {
      this.info.provinceName = selectedOptions[0].label
      this.info.cityName = selectedOptions[1].label
      this.info.areaName = selectedOptions[2].label

      this.info.provinceCode = selectedOptions[0].value
      this.info.cityCode = selectedOptions[1].value
      this.info.areaCode = selectedOptions[2].value
      this.$forceUpdate()

    },
    handleSave() {
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.info.receiveProvinceCode == null || this.info.receiveProvinceCode == undefined) {
            return this.$message.warning('请选择发货目的地')
          }

          //提示全部发完
          if (this.shipmentList.every((item) => item.unfinishedShipmentQuantity === 0)) {
            return this.$message.warning('目的地发货单数量已发完')
          }

          // 验证当前发货数量是不是空的， 必须填   还有发货量的数
          let tip = null
          this.shipmentList.map((item, index) => {
            if (item.unfinishedShipmentQuantity > 0) {
              if (tip == null && (item.shipmentQuantity == null || item.shipmentQuantity == undefined)) {
                tip = '需求' + (index + 1) + '实际发货数不能为空'
              } else if (item.shipmentQuantity <= 0) {
                tip = '需求' + (index + 1) + '实际发货数不能小于0'
              } else if (item.shipmentQuantity > item.unfinishedShipmentQuantity) {
                tip = '需求' + (index + 1) + '实际发货数不能大于未发数量'
              }
            }
          })
          if (tip != null) {
            return this.$message.warning(tip)
          }
          // if (!this.shipmentList.every((item) => item.shipmentQuantity)) {
          //   return this.$message.warning('请填写实际发货数量')
          // }
          delete this.info.uploadFiles
          // delete this.info.shipmentList
          let formData = this.info

          formData.shipmentItems = this.shipmentList.filter(item => item.unfinishedShipmentQuantity > 0)
          formData.shipmentNo = this.info.shipmentNo
          formData.orderNo = this.orderNo
          formData.shipmentFile = this.info.shipmentFile || ''
          this.confirmLoading = true
          formData.shipmentStatus = 1
          const url = this.info.id ? '/order/actualShipment/updById' : '/order/actualShipment/saveActualShipment'
          postAction(url, formData)
            .then((res) => {
              const { success, message, data } = res
              this.confirmLoading = false
              if (success) {
                this.info = data
                this.info.shipmentTime = this.info.shipmentTime && dayjs(new Date(this.info.shipmentTime)).format('YYYY-MM-DD') || null
                this.info.selectedValue = this.info?.receiveProvinceName + ' ' + this.info?.receiveCityName + ' ' + this.info?.receiveAreaName + ' ' + this.info?.receiveDetailAddress
                this.handleReloadData(1) // 刷新主列表
                this.$message.success('保存成功')
                // this.handleCancel()
              } else {
                this.$message.warning(message)
              }
            })
            .catch((e) => {
              this.confirmLoading = false
            })

        }
      })


    },
    onEdit(item) {
      debugger
      this.activeKey = 1
      this.info = item
      console.log(item)
      this.activePart = this.addressList.findIndex(item1 => item1.shipmentOrderNo === this.info.shipmentOrderNo)
      this.shipmentOrderNo = this.info.shipmentOrderNo
      this.info.selectedValue = this.addressList[this.activePart]?.receiveProvinceName + ' ' + this.addressList[this.activePart]?.receiveCityName + ' ' +
        this.addressList[this.activePart]?.receiveAreaName + ' ' + this.addressList[this.activePart]?.receiveDetailAddress
      this.info.shipmentTime = this.info.shipmentTime && dayjs(new Date(this.info.shipmentTime)).format('YYYY-MM-DD') || null

      if (this.info.shipmentFile) {
        this.uploadFiles.push({
          srcFileName: this.info.shipmentFile.split('/images/')['1'],
          url: this.info.shipmentFile
        })
        this.info.uploadFiles = this.uploadFiles
      }

      this.handleLoadShipment(() => {
        this.shipmentList.forEach(shipmentItem => {
          const match = this.info.shipmentItems.find(
            deliveryItem => deliveryItem.skuName === shipmentItem.skuName
          )
          console.log('-------------------------match')
          console.log(match)
          if (match) {
            shipmentItem.shipmentQuantity = match.shipmentQuantity
            shipmentItem.unfinishedShipmentQuantity = match.unfinishedShipmentQuantity
            shipmentItem.finishedShipmentQuantity= match.finishedShipmentQuantity
          }
        })
      })

      this.$forceUpdate()
    },
    handleProvinceChange(value) {
      this.activePart = value
      this.info = this.addressList[value]
      this.info.selectedValue = this.addressList[this.activePart]?.receiveProvinceName + ' ' + this.addressList[this.activePart]?.receiveCityName + ' ' + this.addressList[this.activePart]?.receiveAreaName + ' ' + this.addressList[this.activePart]?.receiveDetailAddress
      this.shipmentOrderNo = this.transportsVo[value].shipmentOrderNo
      this.handleLoadShipment(() => {
      })

    },
    handleDel() {
      this.confirmLoading = true
      const playload = {}
      playload.id = this.shipmentId
      postAction('/order/actualShipment/deleteById', playload)
        .then((res) => {
          const { success, message } = res
          this.confirmLoading = false
          if (success) {
            this.visibleDel = false
            if (this.info.id == this.shipmentId) {
              this.info.id = null
            }
            this.handlequeryRecordByOrderNo()
          } else {
            this.$message.warning(message)
          }
        })
        .catch((e) => {
          this.confirmLoading = false
        })
    }
    , onDelete(item) {
      this.shipmentId = item.id
      this.visibleDel = true
    }, getNumberToChinese(num) {
      let chineseNums = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
      let result = String(num).split('').map(digit => chineseNums[parseInt(digit)]).join('')
      return result
    },
    onDeliveryDownload(item) {
      downGetFiles('/order/delivery/download', {
        id: item.id
      }).then((res) => {
        let url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', window.TemplateName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // 下载完成移除元素
        window.URL.revokeObjectURL(url) // 释放掉blob对象
      })

    }, handleSwitchTab() {
      if (this.activeKey == 1) {
        this.shipmentList = []
        this.handleLoadOrder()
        if (this.info.id) {
          this.handleLoadShipment(() => {
          })
        }
      } else if (this.activeKey == 2) {
        this.handlequeryRecordByOrderNo()
      }
    }

  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/form.less';

.uploadCover {
  width: 560px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}

.NumberInfo {
  color: rgba(0, 0, 0, 0.45);
}

.ant-form-item-label > label {
  color: #000;
}

.info-form {
  // ::v-deep .ant-form-item-label {
  //   width: 96px !important;
  // }
}

.step_form {
  ::v-deep .ant-tabs-content {
    padding-left: 25px;
  }
}

// ::v-deep .ant-tabs-ink-bar-animated {
//   height: 24px!important;
// }
::v-deep .ant-tabs-nav .ant-tabs-tab {
  //padding: 1px 0;
  //margin-bottom: 24px;
  //display: flex;
  //align-items: center;
}

::v-deep .ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.green_btn:not([disabled]) {
  background-color: #05c29c;
  border-color: #05c29c;
}

span.label-span {
  display: inline-block;
  width: 90px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

img.to_input {
  width: 52px;
  margin-right: 15px;
}

::v-deep .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.lastBtn {
  ::v-deep .ant-input {
    padding-right: 56px;
  }
}

.page-title {
  &:not(:first-of-type) {
    margin-top: 28px;
  }

  &:first-of-type {
    margin-top: -8px;
  }

  &.not-first {
    margin-top: 0;
  }

  font-size: 15px;
  color: #605f5f;
  margin-top: 8px;
  margin-bottom: 24px;
  height: 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  position: relative;

  &:before {
    background: #FF6026;
    display: inline-block;
    content: '';
    width: 5px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 10px;
  }

  .action {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: #ff6026;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;

    img.icon {
      width: 16px;
      margin-right: 4px;
    }
  }
}

.table-box {
  min-height: auto !important;

  ::v-deep .ant-table-tbody tr:not(:last-of-type) > td {
    border-right: 1px solid #e8e9eb !important;
  }

  ::v-deep .ant-table-thead > tr > th {
    background: #f4f5f7;
    border-right: 1px solid #e8e8e8 !important;
    border-bottom: 1px solid #e8e8e8 !important;
    padding: 8px 10px;
    font-size: 14px;
  }

  ::v-deep .ant-table-column-title {
    font-size: 14px;
  }

  ::v-deep .ant-table-tbody tr > td {
    border-bottom: 1px solid #e8e9eb !important;
    padding: 5px 10px;
  }

  ::v-deep .step_form .ant-tabs-content {
    padding-left: 0px !important;
  }

  ::v-deep .ant-input-number {
    width: 100%;
    height: 28px;
    line-height: 28px;

    .ant-input-number-input {
      height: 28px;
      font-size: 14px;
      font-weight: 350;

      &::placeholder {
        color: #8c8c8c;
        /* 设置占位符文本的颜色 */
      }
    }
  }

  ::v-deep .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  ::v-deep .ant-input {
    border: none;
    outline: 0;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  ::v-deep .ant-input-number-handler-wrap {
    display: none;
  }
}

.uploadCover {
  width: 100%;
  border: 1px solid #efefef;
}

::v-deep .ant-tabs .ant-tabs-left-content {
  border-left: 0px !important;
}

::v-deep .step_form .ant-tabs-content {
  padding-left: 0px;
}

::v-deep .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: 0rpx;
  display: none;
}

::v-deep .ant-tabs .ant-tabs-left-bar {
  border-right: 0px
}

::v-deep .ant-tabs-bar {
  border-bottom: 0px;
}

.receiveInfo {
  display: flex;
  flex-direction: column;
  background: #F7F8FA;
  border-radius: 4px;
  padding: 16px 0px;
  /* 14/常规 */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* or 22px */
  color: #595959;
}

.receive-order {
  margin-top: 24px;
}

::v-deep .receive-order .ant-descriptions-view {
  border-radius: 4px 4px 0px 0px !important;

}

::v-deep .receive-order .ant-table-wrapper {
  border-bottom: none !important;
}

::v-deep .receive-order .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0px !important;
  border-top: 0px !important;
}

::v-deep .receive-order .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0px !important;
}

::v-deep .receive-order .ant-table-body table {
  border-top: none !important;
}

.receive-order {
  border-top-left-radius: 0px !important;
}

::v-deep .receive-order table {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  box-shadow: none !important;
}

.send-delivery {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #131212;

}

.desc-title {

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #131212;
  width: 311px;
  padding: 8px 16px;


}

.desc-time {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #131212;
  width: 311px;
  padding: 8px 16px;


}

.desc-operate {
  width: 311px;
  padding: 8px 16px;
  /* 14/常规 */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* 品牌主题色 */
  color: #FF6026;
  justify-content: flex-end;

}

.receive-th {
  display: flex;
  background: #FAFAFA;
  border: 1px solid #E8E9EB;
  justify-content: space-between;
}

.receive-tr {
  display: flex;
  border: 1px solid #E8E9EB;
  border-top: none;

}

.receive-td {
  padding: 10px 16px;
  width: 311px;
  border-right: 1px solid #E8E9EB;

}

::v-deep .receive-order .table-box .ant-table-thead > tr > th {
  padding: 10px 16px;
}

::v-deep .receive-order .table-box .ant-table-tbody tr > td {
  padding: 10px 16px;
}

.placeSelect ::-webkit-input-placeholder {
  color: transparent; /* 隐藏 placeholder 文本 */
}

.placeSelect :-moz-placeholder {
  color: transparent; /* 隐藏 placeholder 文本 (Firefox 4-18) */
}

.placeSelect ::-moz-placeholder {
  color: transparent; /* 隐藏 placeholder 文本 (Firefox 19+) */
}

.placeSelect :-ms-input-placeholder {
  color: transparent; /* 隐藏 placeholder 文本 (IE 10+) */
}
</style>
